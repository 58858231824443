.diplom {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}
.diplomImage {        
    width: 50%;
}
.ERPcontainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@media screen and (max-width: 950px) {
    .diplom {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .diplomImage {        
        width: 200px;
    }
    .ERPcontainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: "0px 0px 0px 0px";
    }
    .ERPcontainer div{
        margin-bottom: 50px;
    }
}