.CardDoc {
    filter: drop-shadow(0px 20px 30px rgba(40, 38, 156, 0.15));
    width:100%;
    height:350px;
}

.ModalPDF {
    margin-top: 10px;
}

#card {
    opacity: 0.5;
}

.CardDoc:hover{
    transition: all 500ms ease-in-out;
    filter: drop-shadow(0px 21px 30px rgba(40, 38, 156, 0.17));
}

.Document h2 {
    margin:90px 0 30px 0;
    font-size: 36px;
}

@media screen and (max-width: 1160px){
    .Document h2 {
        margin:80px 0 30px 0;
        font-size: 28px;
    }
    .Document {
        padding: 0 15px;
    }
    .CardDoc {
        height:85%;
    }
}