.styleH2 {
    font-size: 36px;
}

.styleP {
    font-size: 14px;
}

@media screen and (max-width: 1160px){
    #aboutUs {
        padding: 0 15px;
    }

    .styleH2 {
        font-size: 28px;
    }

    .styleP {
        font-size: 12px;
    }
}