.ModalPDF {
    font-size: 16px;
    height: 45px;
}

.ModalPDF:hover {
    transition: all 500ms ease-in-out;
    filter: drop-shadow(0px 2px 20px rgba(40, 38, 156, 0.20));
}
@media screen and (max-width: 1160px){
    .ModalPDF {
        font-size: 14px;
        height: 40px;
    }
}