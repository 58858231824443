.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.styleH4{
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
}

.description {
    font-size: 16px;
}
.container {
    display: flex;
    width: 100%;
    align-items: center;
    /* background-color: brown; */
    /* justify-content: space-between; */
}
.card {
    width: 500px;
    height: 400px;
}
.im {
    margin: auto;
    width: 413px;
    height: 265px;
    background-repeat: no-repeat;
    background-position: center center;
}

@media screen and (max-width: 1160px){

    .description {
        font-size: 12px;
    }
    .styleH4{
        font-size: 15px;
    }
    .container {
        margin: auto;
    }
}

@media screen and (max-width: 900px){
    .container {
        display: grid;
        align-items: center;
    }
    .card {
        width: 90vw;
        height: 400px;
        margin-bottom: 40px;
        margin: auto;
    }
    .im {
        transform: scale(0.7);
        width: 413px;
        height: 265px;
    }
    .main {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}