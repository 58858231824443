.EN:hover {
    background-color: #272590!important;
    color: white !important;
    border-radius: 15px;
    transition: all 0.2s ease-out;
}

.RU:hover {
    background-color: #272590!important;
    color: white !important;
    border-radius: 15px;
    transition: all 0.2s ease-out;
}
