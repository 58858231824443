#contacs {
    padding:35px 0 35px ;
}

.blockInfo {
    display: flex;
    flex-direction: row;
}

.FooterLogo {
    display: flex;
    font-size: 11px;
    width: 100%;
}

.styleH3 {
    font-size: 24px;
}

.FooterLogo strong {
    color: white;
    font-size: 12px;
}

.FooterLogo img {
    width: 38px;
    height: 38px;
}

.FooterLogo p {
    color: white;
    margin-left: 12px;
    text-align: left;
    margin-top: 1px;
}

.RowStyle {
    font-size: 30px;
    float: right;
    padding: 110px 0 0 15px;
    margin:0
}

.RowStyle:hover {
    transition:all 1s ease-in-out;
    transform: scale(1.1);
}
.linkser {
    color: #272590!important;
}

.linkButt {
    padding:5px 15px;
    font-size: 12px;
    text-align: left;
    width: 118px;
}

.linkButt:hover {
    background: #272590!important;
    border-radius: 15px;
    transition: all 0.2s ease-out;
}

.linkser:hover {
    background: #272590!important;
    color: white !important;
    border-radius: 15px;
    transition: all 0.2s ease-out;
}

.nav-links{
    padding:5px 15px;
    width: 118px;
    font-weight: 300;
}

p a {
    color: white;
    text-decoration: none;
}

p a:hover{
    color: #19BE00;
}

.ButtonCart {
    width: 200px;
}

.NavMenu {
    padding: 11px 0 0 15px;
}

.IzhYug {
   display: flex;
    flex-direction: row;
}

.HeadOffice {
    width: 27%;
}

.Yugorsk {
    width: 150%;
}


@media screen and (max-width: 1160px){
    #contacs {
        padding: 50px 15px 50px 15px;
    }
    .styleH3 {
        font-size: 18px;
    }
    .linksor {
        font-size: 12px;
    }
    .nav-links{
        text-align: left;
    }
    .IzhYug {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .HeadOffice {
        width: auto;
        padding-right: 76px;
    }
    .Yugorsk {
        width: 100%;
    }
}

@media screen and (max-width: 768px){
    .nav-links{
        text-align: center;
    }
    .blockInfo {
        display: flex;
        flex-direction: column;
    }
    .IzhYug {
        padding-top: 10px;
        margin: 0 auto;
        text-align: center;
    }
    .HeadOffice {
        margin: 0 auto;
        text-align: center;
        padding: 40px 0 0 0;
    }
    .NavMenu {
        padding: 15px 0 0 0;
        margin: 0 auto;
        align-items: center;
    }
    .linkButt {
        text-align: center;
        width: 118px;
    }
    .RowStyle {
        font-size: 30px;
        padding: 0;
    }
    .Arrow {
        padding-top: 15px;
        margin: 0 auto;
    }
}