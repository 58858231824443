.styleH4{
    display: flex;
    justify-content: center;
    font-size: 18px;
}

.description {
    font-size: 16px;
}


@media screen and (max-width: 1160px){

    .description {
        font-size: 12px;
    }
    .styleH4{
        font-size: 15px;
    }
}