.styleH5 {
    font-size: 18px;
}


.r {
    transform: translateX(150px);
}

.l {
    transform: translateX(-150px);
}

.left {
    transform:translate(-110%, -52.5%);
    height:60%;
    width:40%;
    padding: 0 15px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.4);
}

.right {
    transform:translate(10%, -52.5%);
    height:60%;
    width:40%;
    margin: 0;
    padding: 0 15px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.4);
}

@media screen and (max-width: 1160px){
    #activity {
        padding: 0 15px;
    }

    .styleH5 {
        font-size: 15px;
    }

    .blockText {
        height:22%;
        transform:translate(-50%, 119%);
        width:95%;
    }
}

@media screen and (max-width: 768px) {
    .styleH5 {
        font-size: 12px;
    }

    .blockText {
        height:25%;
        transform:translate(-50%, 90%);
        width:95%;
    }
    #sliderP {
        font-size: 9px;
    }
}