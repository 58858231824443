.CarouselItem img {
    width: 100%;
    height: 620px;
    overflow: hidden;
    object-fit: cover;
    object-position: 50% 50%;
}

.CarouselItem:before {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.6); /*partially transparent image*/
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.content h1 {
    width: 1139px;
    display: flex;
    justify-content: center;
    font-size: 48px;
    margin-bottom: 15px;
    color: #183323;
    font-weight: 900;
}

.content h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #183323;
    font-weight: 300;
}

.content {
    text-align: center;
}


@media screen and (max-width: 1160px){
    .content h1 {
        font-size: 30px;
        text-align: start;
        width: 712px;
    }
    .content h3 {
        font-size: 18px;
    }
    .content p {
        font-size: 15px;
    }
}

@media screen and (max-width: 768px){
    .content h1 {
        font-size: 30px;
        text-align: center;
        width: auto;
    }
    .content h3 {
        font-size: 18px;
        width: 300px;
        margin: 0 auto 15px auto;
    }
    .content p {
        font-size: 15px;
    }
}