:root {
    --btn-w: 200px;
    --dot-w: calc(var(--btn-w)*.2);
    --tr-X: calc(var(--btn-w) - var(--dot-w));
}
* {box-sizing: border-box;}
*:before, *:after {box-sizing: border-box;}

.btn {
    position: relative;
    margin: 0 auto;
    width: var(--btn-w);
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}
.dot {
    content: '';
    position: absolute;
    top: 0;
    width: 15px;
    height: 100%;
    transition: all 300ms ease;
    display: none;
}
.dot:after {
    content: '';
    position: absolute;
    left: calc(50% - .4em);
    top: -.4em;
    height: .8em;
    width: .8em;
    background: #272590;
    border-radius: 2em;
    border: .25em solid white;
    box-shadow: 0 0 .7em #272590,
    0 0 2em #272590;
}
.btn:hover .dot,
.btn:focus .dot {
    animation: atom 2.5s infinite linear;
    display: block;
}
@keyframes atom {
    0% {transform: translateX(0) rotate(0);}
    30%{transform: translateX(var(--tr-X)) rotate(0);}
    50% {transform: translateX(var(--tr-X)) rotate(180deg);}
    80% {transform: translateX(0) rotate(180deg);}
    100% {transform: translateX(0) rotate(360deg);}
}

@media screen and (max-width: 1160px){
    .btn {
        font-size: 15px;
    }
}