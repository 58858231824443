html {
    scroll-behavior: smooth;
}
:target {
    scroll-margin-top: 80px;
}

.NavbarItems {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}

.navbar-logo strong {
    color: #19BE00;
}
.navbar-logo p strong {
    color: #19BE00;
    font-size: 12px;
}

.navbar-logo {
    display: flex;
    font-size: 11px;
}

.navbar-logo img {
    width: 38px;
    height: 38px;
}

.navbar-logo p {
    margin-left: 12px;
    text-align: left;
    margin-top: 2px;
    font-size: 12px;
}

.nav-menu {
    list-style: none;
    text-align: center;
}

.nav-links {
    color: #183323;
    font-size: 12px;
    text-decoration: none;
}

#nav-link {
    text-align: center;
    width: 100%;
}

.nav-links:hover {
    background-color: #272590;
    color: white;
    border-radius: 15px;
    font-size: 12px;
    transition: 0.2s ease-out;
}

.fa-bars {
    color: #183323;
}

.menu-icon {
    display: none;
}

#dropdown-basic-button:hover {
    background-color: #272590;
    color: white;
    transition: all 0.2s ease-out;
}

.DropItem {
    font-size: 12px;
}

@media screen and (max-width: 1160px){
    .NavbarItems {
        position: relative;
        display: flex;
        align-items: center;
        top: 0px;
        /* background-color: antiquewhite; */
    }

    .ButtonLanguage {
        position: absolute;
        top:33px;
        right: 57px;
    }

    .nav-menu {
        display: none;
        width: 100%;
        height: 100vh;
        position: absolute;
        /* right: -100%; */
        opacity: 1;
        z-index: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        display: flex;
        flex-direction: column;
        left: 0;
        top:80px;
        padding: 15px;
        opacity: 1;
        z-index: 1;
        transition: all 0.5s ease;
        background: rgba(255, 255, 255, 0.85);
    }

    .nav-links {
        text-align: center;
        /* padding: 2rem; */
        width: 100%;
        display: table;
        font-size: 12px;
    }

    .nav-links:hover {
        background-color: #272590;
        color: white;
        border-radius: 15px;
        font-size: 12px;
    }

    .navbar-logo p {
        margin-top: 0px;
        font-size: 15px;
    }
    .navbar-logo p strong {
        margin-top: 0px;
        font-size: 15px;
    }
    .navbar-logo {
        padding: 0 15px;
        position: absolute;
            top:0;
            left: 0;
        transform: translate(0%,50%);
    }

    .menu-icon {
        padding: 0 15px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0%,60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #183323;
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px){
    .ButtonLanguage {
        position: absolute;
        top:0;
        right: 5px;
    }
}