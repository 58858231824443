.partner {
    filter: drop-shadow(0px 10px 30px rgba(40, 38, 156, 0.15));
}

.partner:hover{
    transition: all 500ms ease-in-out;
    filter: drop-shadow(0px 11px 30px rgba(40, 38, 156, 0.17));
}

#partners {
    padding: 0 15px;
}

.InAk {
    padding-top: 15px;
}

.RZD {
    margin: 0 auto;
    padding-top: 15px;
}

.IRZ {
    margin-top: 15px;
}