.reviews {
    filter: drop-shadow(0px 10px 30px rgba(40, 38, 156, 0.15));
}

.reviews:hover {
    transition: all 500ms ease-in-out;
    filter: drop-shadow(0px 11px 30px rgba(40, 38, 156, 0.17));
}

#reviews {
    padding: 0 15px;
}

#reviews h2 {
    margin:90px 0 30px 0;
}
@media screen and (max-width: 1160px){
    #reviews h2 {
        margin:70px 0 30px 0;
    }
}

@media screen and (max-width: 768px){
    #reviews h2 {
        margin:95px 0 30px 0;
    }
}
