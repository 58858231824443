.styleIMG {
    height:80%;

}

@media screen and (max-width: 1160px){
    .styleIMG {
        height:350px;
    }

    .TechEquipment {
        padding: 0 15px;
    }
}

@media screen and (max-width: 1160px) {
    .styleIMG {
        height:250px;
    }

    #cardH4 {
        font-size: 12px;
        text-align: center;
    }

    #cardP {
        font-size: 9px;
        text-align: center;
    }

    .TechEquipment {
        padding: 0 15px;
    }
}