.slider {
    width: 1140px;
    height: 100%;
    margin: 0 auto
}

@media screen and (max-width: 1160px) {
    .slider {
    padding: 0 15px;
        width: 100%;
    }
}