.videoBlock {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.videoPlayer {
    align-items: center;
    width: 95%;
    height: 500px;
}
.main {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

@media screen and (max-width: 950px) {
    .videoPlayer {
        align-items: center;
        width: 100%;
        height: 100%;
    }
}